<template lang="pug">
article.franchise
  .franchise__cont.cont
    h1.franchise__caption.caption Открой свой Музей «Шоколадный Выборг»
    p.franchise__text
      | Музей-магазин шоколада — это формат заведения,  включающий экспозицию, посвященную истории шоколада, магазин
      | шоколада ручной работы и интерактивную часть — мастер-классы для посетителей по приготовлению шоколада.
    ul.franchise__list
      li.franchise__item(v-for="item in list", :key="item")
        span.franchise__headline.headline {{item.headline}}
        p.franchise__note {{item.note}}
</template>

<script>
import '@/assets/styles/components/franchise.sass'
export default {
  name: 'TheFranchise',
  data () {
    return {
      list: [
        { headline: 'Низкая стоимость запуска', note: 'Экономичный старт бизнеса' },
        { headline: 'Высокая окупаемость', note: 'Быстрый возврат вложенных средств' },
        { headline: 'Низкая конкурентность', note: 'Уникальность концепции на рынке' },
        { headline: 'Открытость', note: 'Без роялти и паушальных взносов' },
        { headline: 'Прибыль', note: 'Средняя выручка от 1 500 000 р. в месяц' },
        { headline: 'Быстрый запуск', note: 'Минимальные сроки подготовки и открытия' }
      ]
    }
  }
}
</script>
