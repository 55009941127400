<template lang='pug'>
.franchise-project
  .franchise-project__cont.cont
    h2.franchise-project__title.title Готовый бизнес-проект Музея
    p.franchise-project__text.franchise-project__text_full
      | Наши специалисты разработают проект, который будет включать полный пакет документов,
      | а также 3D визуализацию будущего дизайна помещения.
    ul.franchise-project__list
      li.franchise-project__item(v-for="item in list", :key="item")
        span.franchise-project__headline.headline {{item.headline}}
        p.franchise-project__note.text {{item.note}}

    .franchise-project__download
      picture.franchise-project__download-picture
        source(:srcset="`${pathDownloadImage}_328.webp, ${pathDownloadImage}_656.webp 2x`", type="image/webp", media="(max-width: 520px)")
        source(:srcset="`${pathDownloadImage}_1176.webp`", type="image/webp")
        source(:srcset="`${pathDownloadImage}_328.jpg, ${pathDownloadImage}_656.jpg 2x`", media="(max-width: 520px)")
        img.franchise-project__download-image(:src="`${pathDownloadImage}_1176.jpg`", alt="Пример дизайн проекта Музея «Шоколадный Выборг»", title="Пример дизайн проекта Музея «Шоколадный Выборг»", loading="lazy", width="1176", height="500")
      a.franchise-project__download-link.link-more(href="#franchise-contacts") Скачать

    h2.franchise-project__title.title
      | Разработка и производство тематического оснащения
    p.franchise-project__text
      | Это не просто музей, а необычный магазин со своей концепцией: его витрина выглядит, как самая настоящая
      | выставка шоколада невероятных форм и размеров.
    .franchise-project__works
      span.franchise-project__works-subtitle Мы сами производим
      ul.franchise-project__works-list
        li.franchise-project__works-item(v-for="(item, i) in projectWorks", :key="i")
          picture.franchise-project__works-picture
            source(:srcset="`${item.path}_328.webp, ${item.path}_656.webp 2x`", type="image/webp", media="(max-width: 520px)")
            source(:srcset="`${item.path}_228.webp, ${item.path}_456.webp 2x`", type="image/webp", media="(max-width: 700px)")
            source(:srcset="`${item.path}_208.webp, ${item.path}_416.webp 2x`", type="image/webp", media="(max-width: 950px)")
            source(:srcset="`${item.path}_204.webp, ${item.path}_408.webp 2x`", type="image/webp", media="(max-width: 1240px)")
            source(:srcset="`${item.path}_276.webp, ${item.path}_414.webp 1.5x`", type="image/webp")
            source(:srcset="`${item.path}_276.jpg, ${item.path}_414  .jpg 1.5x`")
            img.franchise-project__works-image(:src="`${item.path}_276.jpg`", :alt="item.alt", :title="item.alt", loading="lazy", width="276", height="184")
          span.franchise-project__works-name {{item.name}}

    h2.franchise-project__title.title Необходимый штат
    .franchise-project__staff-area
      ul.franchise-project__staff-list
        li.franchise-project__staff-item(v-for="item in staffList", :key="item")
          span.franchise-project__staff-position.text-big {{item.name}}
          span.franchise-project__staff-count.text-big {{item.count}}
      picture.franchise-project__staff-picture
        source(:srcset="`${pathStaffImage}_328.webp, ${pathStaffImage}_656.webp 2x`", type="image/webp", media="(max-width: 520px)")
        source(:srcset="`${pathStaffImage}_228.webp, ${pathStaffImage}_456.webp 2x`", type="image/webp", media="(max-width: 950px)")
        source(:srcset="`${pathStaffImage}_328.webp, ${pathStaffImage}_656.webp 2x`", type="image/webp", media="(max-width: 1240px)")
        source(:srcset="`${pathStaffImage}_576.webp, ${pathStaffImage}_864.webp 1.5x`", type="image/webp")
        source(:srcset="`${pathStaffImage}_576.jpg, ${pathStaffImage}_864.jpg 1.5x`")
        img.franchise-project__staff-image(:src="`${pathStaffImage}_576.jpg`", alt="Шоколатье Музея «Шоколадный Выборг»", title="Шоколатье Музея «Шоколадный Выборг»", loading="lazy", width="576", height="576")

    h2.franchise-project__title.title  Пройдем путь шаг за шагом
    .franchise-project__step-area
      ul.franchise-project__step-list
        li.franchise-project__step-item(v-for="item in stepList", :key="item")
          span.franchise-project__headline.headline {{item.headline}}
          p.franchise-project__note.text {{item.note}}
      .franchise-project__step-pictures
        picture.franchise-project__step-left-picture
          source(:srcset="`${pathStepImages[0]}_197.webp, ${pathStepImages[0]}_394.webp 2x`", type="image/webp", media="(max-width: 520px)")
          source(:srcset="`${pathStepImages[0]}_281.webp, ${pathStepImages[0]}_562.webp 2x`", type="image/webp", media="(max-width: 1240px)")
          source(:srcset="`${pathStepImages[0]}_348.webp, ${pathStepImages[0]}_522.webp 1.5x`", type="image/webp")
          source(:srcset="`${pathStepImages[0]}_348.jpg, ${pathStepImages[0]}_522.jpg 1.5x`")
          img.franchise-project__step-left-image(:src="`${pathStepImages[0]}_348.jpg`", alt="Пример шоколадных фигурок от Музея «Шоколадный Выборг»", title="Пример шоколадных фигурок от Музея «Шоколадный Выборг»", loading="lazy", width="348", height="348")
        picture.franchise-project__step-right-picture
          source(:srcset="`${pathStepImages[1]}_132.webp, ${pathStepImages[1]}_264.webp 2x`", type="image/webp", media="(max-width: 520px)")
          source(:srcset="`${pathStepImages[1]}_188.webp, ${pathStepImages[1]}_376.webp 2x`", type="image/webp", media="(max-width: 1240px)")
          source(:srcset="`${pathStepImages[1]}_256.webp, ${pathStepImages[1]}_384.webp 1.5x`", type="image/webp")
          source(:srcset="`${pathStepImages[1]}_256.jpg, ${pathStepImages[1]}_384.jpg 1.5x`")
          img.franchise-project__step-right-image(:src="`${pathStepImages[1]}_256.jpg`", alt="Пример сувенирной шоколадной продукции от Музея «Шоколадный Выборг»", title="Пример сувенирной шоколадной продукции от Музея «Шоколадный Выборг»", loading="lazy", width="256", height="388")

    h2.franchise-project__title.title  Бизнес-пакеты для вашего Музея
    ul.franchise-project__package-list
      li.franchise-project__package-item(v-for="(item, i) in packageList", :key="i")
        img.franchise-project__package-image(:src="`${item.img}.svg`", :alt="`${item.headline} бизнес-пакет - фото`", :title="`${item.headline} бизнес-пакет - фото`", loading="lazy", width="100", height="100")
        span.franchise-project__package-headline.headline {{item.headline}}
        p.franchise-project__package-description.text {{item.description}}
</template>

<script>
import '@/assets/styles/components/franchise-project.sass'
export default {
  name: 'FranchiseProject',
  data () {
    return {
      list: [
        { headline: 'Подбор помещения', note: 'Поможем подобрать оптимальный вариант помещения с учетом пешеходного потока, конкурентной среды, туристических маршрутов' },
        { headline: 'Обучение', note: 'Обучение партнеров, управляющих и шоколатье в соответствии со всеми нормами и требованиями производства' },
        { headline: 'Вышлем команду для старта', note: 'Выезд стартап команды для подготовки к открытию Музея Шоколада. Рекомендации и контроль перед запуском заведения' },
        { headline: 'Поддержка', note: 'Выбирая нас вы получаете пошаговую инструкцию по запуску своего дела. Оказываем консультационную и маркетинговую поддержку, предоставляем поставку основных товаров, оборудования: стабильное качество и низкие цены' }
      ],
      pathDownloadImage: '/images/franchise-project/franchise-project-1',
      projectWorks: [
        { path: '/images/franchise-project/franchise-project-2', alt: 'Силиконовые формы для шоколада', name: 'Силиконовые формы' },
        { path: '/images/franchise-project/franchise-project-3', alt: 'Тематическая бутафория городских достопримечательностей', name: 'Тематическую бутафорию под ваш город' },
        { path: '/images/franchise-project/franchise-project-4', alt: 'Шоколадная сувенирная продукция для бизнеса', name: 'Сувенирную продукцию для бизнеса' },
        { path: '/images/franchise-project/franchise-project-5', alt: 'Вывески для бизнеса', name: 'Вывески' }
      ],
      pathStaffImage: '/images/franchise-project/franchise-project-6',
      staffList: [
        { name: 'Шоколатье', count: '×1' },
        { name: 'Упаковщик', count: '×1' },
        { name: 'Работник кухни', count: '×1' },
        { name: 'Администратор', count: '×1' },
        { name: 'Продавец', count: '×2' }
      ],
      stepList: [
        { headline: 'Брендирование и техническое оснащение Музея', note: 'Рассчитаем стоимость изготовления конструкций  с последующим брендированием, выбираем системы лояльности, заказываем техническое оборудование согласно смете' },
        { headline: 'Стажировка в учебном центре', note: 'Вы и ваши специалисты пройдете обучение, изучите все аспекты приготовления шоколада и работы в Музее' },
        { headline: 'Подготовка к открытию', note: 'Подготовка маркетинговых мероприятий к торжественному открытию. Монтируем оборудование, формируем цены и ассортимент' },
        { headline: 'Торжественное открытие Музея', note: 'К вам приедет наша команда профессионалов, которая поможет вам правильно запустить Музей' }
      ],
      pathStepImages: [
        '/images/franchise-project/franchise-project-7',
        '/images/franchise-project/franchise-project-8'
      ],
      packageList: [
        { headline: 'Образовательный', description: 'Обучение по работе с покупателем, скрипты продаж. Выдадим технологические карты и научим работать согласно нормам и требованиям', img: '/images/franchise-project/ic_franchise-project-1' },
        { headline: 'Юридический', description: 'Консультация при открытии Юр.лица, вместе подготовим полный комплект документов: договор аренды, договор с поставщиками, трудовые договора с персоналом', img: '/images/franchise-project/ic_franchise-project-2' },
        { headline: 'Продуктовый', description: 'Познакомим с проверенными поставщиками и ценами на закупку. Создадим брендированную линейку продукции и разработаем прайс', img: '/images/franchise-project/ic_franchise-project-3' },
        { headline: 'Управленческий', description: 'Научим управлять персоналом:  как нанимать сотрудников, как контролировать качество работы, как мотивировать, какие стандарты ставить. Поможем разобраться в финансовой отчетности', img: '/images/franchise-project/ic_franchise-project-4' },
        { headline: 'Маркетинговый', description: 'Сделаем сайт для вашего музея. Разработаем маркетинговый план открытия музея', img: '/images/franchise-project/ic_franchise-project-5' },
        { headline: 'Рекламный', description: 'Предоставим комплект дизайн-макетов для рекламы (акции, баннеры, вывески, листовки, доски, ценники). Научим вести соцсети правильно. Внедрим систему лояльности и дисконтные карты', img: '/images/franchise-project/ic_franchise-project-6' }
      ]
    }
  }
}
</script>
