<template lang="pug">
article.franchise-finances
  .franchise-finances__cont.cont
    h2.franchise-finances__title.title Финансовые показатели
    ul.franchise-finances__list
      li.franchise-finances__item(v-for="(item, i) in list", :key='i')
        span.franchise-finances__note {{ item.note }}
        span.franchise-finances__value.title {{ item.value }}
</template>

<script>
import '@/assets/styles/components/franchise-finances.sass'

export default {
  name: 'FranchiseFinances',
  data () {
    return {
      list: [
        { note: 'Средняя выручка', value: '2,7 млн. руб.' },
        { note: 'Чистая прибыль', value: '1,5 тыс. руб.' },
        { note: 'Рентабельность', value: '50%' },
        { note: 'Стартовые инвестиции', value: '8 млн руб.' }
      ]
    }
  }
}
</script>
